<template>
  <div class="p-mileArchives">
    <div class="c-section">
      <p class="c-lead c-lead--grey01">
        マイルを貯めるとお得なプレゼントが獲得できます！
      </p>
    </div>

    <div
      v-for="(reward, i) in computedRewards"
      :key="i"
      class="p-mileArchives__rank c-section c-section--fluid c-section--white01Bg">
      <div
        class="c-section__inner c-section__inner--pt10 c-section__inner--pb0">
        <div
          class="p-mileArchives__rank__logo"
          :class="{ 'is-disabled': !isCurrentMileStage(i) }">
          <img :src="`/assets/img/flag/${reward.key}.svg`" alt="" />
          {{ reward.rankLabel }}
        </div>
        <div class="c-cards c-cards--tiled">
          <div
            v-for="(item, j) in reward.items"
            :key="j"
            class="c-cards__inner"
            :class="{ 'is-active': isActiveReward(item.uid) }">
            <CouponCardMile
              :src="item.imageUrl"
              :title="item.name"
              :subtitle="getCategory(item.type)"
              tiled>
              <Chip v-if="item.acquired" disabled>達成済</Chip>
              <Chip
                v-else
                :type="isActiveReward(item.uid) ? 'primary' : 'secondary'">
                {{ item.threshold }}
                <span class="c-chip__unit">マイル</span>
              </Chip>
            </CouponCardMile>
          </div>
        </div>
        <!-- /.c-cards.c-cards--tiled -->
      </div>
    </div>

    <div v-if="nextPageUrl" class="p-mileArchives__actions c-section">
      <Button style-type="quaternary" fluid @click="loadMore">
        マイル特典をもっと見る
      </Button>
    </div>
  </div>
</template>

<script>
import {
  RewardType,
  MileStageType,
  SystemDialogMessage
} from '@/constants/enums';

export default {
  components: {
    CouponCardMile: () => import('@/components/mypage/mile/CouponCardMile.vue')
  },

  data: () => ({
    nextPageUrl: null,
    rewards: []
  }),

  computed: {
    /**
     * UI展開用に整形したマイル特典一覧情報
     */
    computedRewards() {
      let uid = 0;
      return this.rewards.map((item) => {
        const mileStageId = MileStageType.getMileStageTypeKeyById(item.rank_id);

        return {
          key: MileStageType.getTypeName(mileStageId)
            .replace(' ', '')
            .toLowerCase(),
          rankLabel: MileStageType.getTypeName(mileStageId),
          items: item.present.map((v) => ({
            uid: uid++, // 特典に通し番号を振る
            acquired: v.acquired,
            threshold: v.threshold,
            name: v.reward.name,
            type: v.reward.type,
            imageUrl: v.reward.image_url
          }))
        };
      });
    },

    flatRewards() {
      return this.computedRewards.flatMap((v) => v.items);
    }
  },

  created() {
    this.fetchList();
  },

  methods: {
    async fetchList() {
      try {
        this.$_loading_start();

        const { data } = await this.$repositories('mileage').getRewards(
          this.$utilities.getUrlSearch(this.nextPageUrl)
        );
        this.rewards.push(...data.data);
        this.nextPageUrl = data.next_page_url;
      } catch {
        window.location.href = this.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      } finally {
        this.$_loading_stop();
      }
    },

    async loadMore() {
      if (this.$_loading_state) return;
      await this.fetchList();
    },

    getCategory(rewardType) {
      return RewardType.getTypeName(rewardType);
    },

    /**
     * n番目のステージが現在のステージかどうか
     *
     * @returns {boolean}
     */
    isCurrentMileStage(n) {
      return (
        n ===
        // 未達成の特典がある最初のステージ
        this.computedRewards.findIndex((reward) =>
          reward.items.some((v) => !v.acquired)
        )
      );
    },

    /**
     * n番目の特典が、次に獲得予定の特典かどうか
     *
     * @returns {boolean}
     */
    isActiveReward(n) {
      return n === this.flatRewards.findIndex((item) => !item.acquired);
    }
  }
};
</script>
