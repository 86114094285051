var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "p-mileArchives" },
    [
      _vm._m(0),
      _vm._l(_vm.computedRewards, function (reward, i) {
        return _c(
          "div",
          {
            key: i,
            staticClass:
              "p-mileArchives__rank c-section c-section--fluid c-section--white01Bg",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "c-section__inner c-section__inner--pt10 c-section__inner--pb0",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "p-mileArchives__rank__logo",
                    class: { "is-disabled": !_vm.isCurrentMileStage(i) },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/assets/img/flag/" + reward.key + ".svg",
                        alt: "",
                      },
                    }),
                    _vm._v(" " + _vm._s(reward.rankLabel) + " "),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "c-cards c-cards--tiled" },
                  _vm._l(reward.items, function (item, j) {
                    return _c(
                      "div",
                      {
                        key: j,
                        staticClass: "c-cards__inner",
                        class: { "is-active": _vm.isActiveReward(item.uid) },
                      },
                      [
                        _c(
                          "CouponCardMile",
                          {
                            attrs: {
                              src: item.imageUrl,
                              title: item.name,
                              subtitle: _vm.getCategory(item.type),
                              tiled: "",
                            },
                          },
                          [
                            item.acquired
                              ? _c("Chip", { attrs: { disabled: "" } }, [
                                  _vm._v("達成済"),
                                ])
                              : _c(
                                  "Chip",
                                  {
                                    attrs: {
                                      type: _vm.isActiveReward(item.uid)
                                        ? "primary"
                                        : "secondary",
                                    },
                                  },
                                  [
                                    _vm._v(" " + _vm._s(item.threshold) + " "),
                                    _c(
                                      "span",
                                      { staticClass: "c-chip__unit" },
                                      [_vm._v("マイル")]
                                    ),
                                  ]
                                ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]
            ),
          ]
        )
      }),
      _vm.nextPageUrl
        ? _c(
            "div",
            { staticClass: "p-mileArchives__actions c-section" },
            [
              _c(
                "Button",
                {
                  attrs: { "style-type": "quaternary", fluid: "" },
                  on: { click: _vm.loadMore },
                },
                [_vm._v(" マイル特典をもっと見る ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "c-section" }, [
      _c("p", { staticClass: "c-lead c-lead--grey01" }, [
        _vm._v(" マイルを貯めるとお得なプレゼントが獲得できます！ "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }